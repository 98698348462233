import React from "react"
import { CSSTransitionGroup } from "react-transition-group"

import "./HeaderSection.scss"

export default class HeaderSection extends React.Component {
  render() {
    return (
      <CSSTransitionGroup
        transitionName="fade"
        transitionEnterTimeout={500}
        transitionLeaveTimeout={500}
        transitionAppear={true}
        transitionAppearTimeout={500}
      >
        <section className="stack-header">
          <div className="container">
            <div className="header row">
              <h1 className="header-title col-lg-10 col-md-9 col-sm-8 col-xs-8">
                Technologies We Use
              </h1>
            </div>
          </div>
        </section>
      </CSSTransitionGroup>
    )
  }
}
