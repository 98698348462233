import React from "react"

import dataForArticles from "../../../information/Technology/ArticlesSection/dataForArticles"

import "./ArticleSection.scss"

const ArticlesSection = () => {
  return (
    <section className="stack-articles">
      <div className="container">
        <div className="article-list row">
          {dataForArticles.map(article => {
            const { id, header, text } = article
            return (
              <div key={id} className="article-list_item">
                <h3>{header}</h3>
                <p>{text}</p>
              </div>
            )
          })}
        </div>
      </div>
    </section>
  )
}

export default ArticlesSection
