import React from "react"

import "./StackSection.scss"

const StackSection = ({ stack, isExtended }) => {
  const descCols = isExtended ? "col-md-6" : "col-md-5"
  const techCols = isExtended ? "col-md-6" : "col-md-7"
  const extended = isExtended ? "extended" : ""
  const { title, description, images } = stack
  return (
    <section className="stack-section">
      <div className="container">
        <div className={"stack row " + extended}>
          <div className={"col-lg-6 col-sm-6 col-xs-12 " + descCols}>
            <h3 className="stack-title">{title}</h3>
            <p className="stack-discription">{description}</p>
          </div>
          <div className={"col-lg-6 col-sm-6 col-xs-12 " + techCols}>
            {images.map((list, i) => (
              <ul key={i} className="stack-list">
                {list.map(item => {
                  const { id, alt, src } = item
                  return (
                    <li key={id} className="stack-list_item">
                      <img src={src} alt={alt} />
                    </li>
                  )
                })}
              </ul>
            ))}
          </div>
        </div>
      </div>
    </section>
  )
}

export default StackSection
