import {
  AWS,
  GoogleCloud,
  Microsoft,
  Oracle,
} from "../../../images/Technology/PlatformSection"

const dataForPlatform = {
  title: "Our partners",
  text:
    "By using the latest practices and services, provided by leading technology companies, we assure the highest quality of implemented solutions for our customers.",
  images: [
    {
      id: 1,
      src: Microsoft,
      alt: "Microsoft logo",
    },
    {
      id: 2,
      src: AWS,
      alt: "AWS logo",
    },
    {
      id: 3,
      src: GoogleCloud,
      alt: "Google Cloud logo",
    },
    {
      id: 4,
      src: Oracle,
      alt: "Oracle logo",
    },
  ],
}

export default dataForPlatform
