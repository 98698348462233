import {
  Firebase,
  Gulp,
  Kubernetes,
  Linux,
  MongoDB,
  MySQL,
  Sass,
  Sencha,
  Symfony,
  WebRTC,
} from "../../../images/Technology/TechSection"

// const dataForTechSection = [
//     [
//       {
//         id: '80',
//         alt: 'mySQL',
//         src: MySQL
//       },
//       {
//         id: '85',
//         alt: 'mongoDB',
//         src: MongoDB
//       },
//       {
//         id: '90',
//         alt: 'firebase',
//         src: Firebase
//       },
//       {
//         id: '95',
//         alt: 'sencha',
//         src: Sencha
//       },
//       {
//         id: '100',
//         alt: 'symfony',
//         src: Symfony
//       }
//     ],
//     [
//       {
//         id: '105',
//         alt: 'gulp',
//         src: Gulp
//       },
//       {
//         id: '110',
//         alt: 'kubernetes',
//         src: Kubernetes
//       },
//       {
//         id: '115',
//         alt: 'webRTC',
//         src: WebRTC
//       },
//       {
//         id: '120',
//         alt: 'sass',
//         src: Sass
//       },
//       {
//         id: '125',
//         alt: 'linux',
//         src: Linux
//       }
//     ]
//   ];

const dataForTechSection = [
  {
    key: 1,
    src: MySQL,
    alt: "mySQL",
  },
  {
    key: 2,
    src: MongoDB,
    alt: "mongoDB",
  },
  {
    key: 3,
    src: Firebase,
    alt: "firebase",
  },
  {
    key: 4,
    src: Sencha,
    alt: "sencha",
  },
  {
    key: 5,
    src: Symfony,
    alt: "symfony",
  },
  {
    key: 6,
    src: Gulp,
    alt: "gulp",
  },
  {
    key: 7,
    src: Kubernetes,
    alt: "kubernetes",
  },
  {
    key: 8,
    src: WebRTC,
    alt: "webRTC",
  },
  {
    key: 9,
    src: Sass,
    alt: "sass",
  },
  {
    key: 10,
    src: Linux,
    alt: "linux",
  },
]

export default dataForTechSection
