import React from "react"

import "./TechSection.scss"

const TechSection = ({ stack }) => {
  return (
    <div className="technologies-container">
      {/* <div className="container">
				<div className="tech row">					
						{
							stack.map((list, i) => (
								<ul key={i} className="tech-list">
									{
										list.map((item) => {
											const { id, alt, src } = item;
											return (
												<li key={id} className="tech-list_item">
													<img src={src} alt={alt} />
												</li>
											)
										})
									}
								</ul>
							))
						}			
				</div>
			</div> */}
      <div className="technologies-wrapper">
        {stack.map(tech => {
          const { key, alt, src } = tech
          return (
            <div className="tech-icon-container" key={key}>
              <img src={src} alt={alt} className="tech-icon" />
            </div>
          )
        })}
      </div>
    </div>
  )
}

export default TechSection
