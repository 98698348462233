import React from "react"

import dataForPlatform from "../../../information/Technology/PlatformSection/dataForPlatform"

import "./PlatformSection.scss"

const PlatformsSection = () => {
  return (
    <section className="platform-section">
      <div className="container">
        <div className="platform">
          <div className="col-flex col-lg-6 col-sm-7 col-md-5 col-xs-12">
            <h3 className="platform-title">{dataForPlatform.title}</h3>
            <p className="platform-description">{dataForPlatform.text}</p>
          </div>
          <div className="platform-list">
            {dataForPlatform.images.map(image => {
              const { id, src, alt } = image
              return (
                <div key={id} className="platform-list_item">
                  <img src={src} alt={alt} />
                </div>
              )
            })}
          </div>
        </div>
      </div>
    </section>
  )
}

export default PlatformsSection
