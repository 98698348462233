import {
  Angular,
  BitBucket,
  Docker,
  Git,
  HTML,
  Java,
  Jira,
  Node,
  PHP,
  Python,
  React,
  Slack,
  Trello,
  Vue,
} from "../../../images/Technology/StackSection"

const dataForStackSection = {
  techstack: {
    title: "Our tech stack",
    description:
      "We combine developers` talent with the best technologies currently avaliable on the market",
    images: [
      [
        {
          id: "10",
          alt: "java",
          src: Java,
        },
        {
          id: "15",
          alt: "python",
          src: Python,
        },
        {
          id: "20",
          alt: "react-js",
          src: React,
        },
        {
          id: "25",
          alt: "angular-js",
          src: Angular,
        },
      ],
      [
        {
          id: "30",
          alt: "node-js",
          src: Node,
        },
        {
          id: "35",
          alt: "html",
          src: HTML,
        },
        {
          id: "40",
          alt: "php",
          src: PHP,
        },
        {
          id: "45",
          alt: "vue-js",
          src: Vue,
        },
      ],
    ],
  },
  communications: {
    title: "Communication",
    description:
      "These tools help us make our work process effective and transparent",
    images: [
      [
        {
          id: "50",
          alt: "trello",
          src: Trello,
        },
        {
          id: "55",
          alt: "docker",
          src: Docker,
        },
        {
          id: "60",
          alt: "github",
          src: Git,
        },
      ],
      [
        {
          id: "65",
          alt: "jira",
          src: Jira,
        },
        {
          id: "70",
          alt: "slack",
          src: Slack,
        },
        {
          id: "75",
          alt: "bitbucket",
          src: BitBucket,
        },
      ],
    ],
  },
}

export default dataForStackSection
